<template>
  <div>
    <h1>Poed</h1>
    <v-btn
        v-show="true"
        dark
        absolute
        top
        right
    >
      Lisa pood
    </v-btn>
    <v-data-table
        :headers="headers"
        :items="shops"
        item-key="id"
        :loading="loading"
        loading-text="Laeb... palun oota"
        :hide-default-footer=true
    >
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Shops",
  data () {
    return {
      shops: [],
      loading: true,
      headers: [
        { text: 'Nimi', value: 'name' },
        { text: 'Veebileht', value: 'url' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Vaikimisi keel', value: 'default_lang' },
        { text: '', value: 'data-table-expand' },
      ]
    }
  },
  mounted () {
    axios.get(axios.defaults.baseURL + '/shops').then((response) => {
      this.shops = response.data;
      this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>